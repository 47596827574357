<template>
  <page-title class="q-mb-lg" :title="$t('account.address')"></page-title>
  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <Form
      ref="rootForm"
      class="q-gutter-md q-mt-lg"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="row form-inputs-content justify-between">
        <Field
          v-for="(structure, key) in structureForm"
          :key="key"
          :name="structure.name"
          v-slot="{ errorMessage, value, field }"
        >
          <q-select
            v-if="structure.type === 'select'"
            style="width: 100%"
            outlined
            :dense="$q.platform.is.mobile"
            filled
            clearable
            bottom-slots
            v-bind="field"
            :type="structure.type"
            :mask="structure.mask"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="value"
            :label="structure.label"
            :error-message="errorMessage"
            :error="!!errorMessage"
            :options="structure.options"
          >
            <template v-slot:before><q-icon :name="structure.icon" /></template>
          </q-select>
          <q-input
            v-else
            style="width: 100%"
            class="q-mb-sm form-inputs-content"
            filled
            :dense="$q.platform.is.mobile"
            bottom-slots
            clearable
            @clear="
              actions[structure.name] ? actions[structure.name](null) : null
            "
            :stack-label="structure[' stack-label']"
            v-bind="field"
            :mask="structure.mask"
            :type="structure.type"
            :readonly="structure.readonly"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="value"
            :label="structure.label"
            :error-message="errorMessage"
            :error="!!errorMessage"
            @change="actions[structure.name]"
          >
            <template v-slot:before><q-icon :name="structure.icon" /></template>
          </q-input>
        </Field>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          outline
          color="grey"
          type="submit"
          :loading="in_request"
          :disable="in_request"
          :label="$t('global.save')"
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
        />
      </q-card-section>
    </Form>
  </q-intersection>
</template>

<script>
import {
  build,
  getStructure,
  getMappedFields,
  buildInitialValues,
  getFormValuesChanged,
} from "@/shared/form-presets";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import PageTitle from "@/modules/main/components/page-title";
import { Field, Form } from "vee-validate";
import cep from "cep-promise";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("account");

export default {
  name: "Address",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const in_request = ref(false),
      fieldsDefinitions = {
        zip_code: "end_cep",
        number: "end_numero",
        complement: "end_complemento",
        address: "end_rua",
        district: "end_bairro",
        city: "end_cidade",
        state: "end_uf",
      };

    const structureForm = getStructure(Object.keys(fieldsDefinitions)),
      schema = build(getMappedFields(Object.keys(fieldsDefinitions)));

    const { partner } = useGetters({
        partner: "getPartnerAccount",
      }),
      { updateUserPartnerAction } = useActions(["updateUserPartnerAction"]);

    let initialValues = buildInitialValues(
      fieldsDefinitions,
      structureForm,
      partner.value
    );

    /**
     * root ref to form
     * @type {ToRef<undefined>}
     */
    const rootForm = ref(undefined);
    rootForm.value = undefined;

    const onSubmit = (values) => {
      let payload = getFormValuesChanged(
        fieldsDefinitions,
        initialValues,
        values
      );

      if (Object.keys(payload).length > 0) {
        initialValues = values;
        in_request.value = true;
        updateUserPartnerAction(payload)
          .then(() => notifySuccess("actions.address_changed_success"))
          .catch(() => notifyError("actions.address_on_changed_error"))
          .finally(() => (in_request.value = false));
      } else {
        notifySuccess("actions.address_changed_success");
      }
    };

    const onZipCodeChanged = (value) => {
      const setFields = (fields) => {
        rootForm.value.setFieldValue("city", fields.city || "");
        rootForm.value.setFieldValue("state", fields.state || "");
        rootForm.value.setFieldValue("address", fields.street || "");
        rootForm.value.setFieldValue("district", fields.neighborhood || "");
        rootForm.value.setFieldValue("complement", "");
      };
      if (!value) {
        setFields({});
        return;
      }
      cep(value.replace(/\D/g, ""), {}).then(setFields);
    };

    return {
      in_request,
      rootForm,
      schema,
      initialValues,
      structureForm,
      onSubmit,
      actions: {
        zip_code: onZipCodeChanged,
      },
    };
  },
};
</script>
